import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { data } from "../../assets/ne_50m_admin_0_countries";
import { FmdGood } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useDimensions } from "../../utils/useDimensions";

const ConnectionMap = ({ width, height, setHeightState }) => {
  const [projectionScale, setProjectionScale] = useState(250);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth });
  let midPoint = [];
  let additionalHeight = height > 900 ? 200 : 100;
  const projection = d3
    .geoMercator()
    .scale(window.innerWidth * 0.08)
    .translate([window.innerWidth / 2, (height + additionalHeight) / 2]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countryAchievements, setCountryAchievements] = useState();

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;

      setWindowSize({ width: newWidth });

      // Adjust scale based on screen width
      if (newWidth < 600) {
        setProjectionScale(100); // Smaller scale for mobile
        setHeightState(500);
      } else if (newWidth < 900) {
        setProjectionScale(180);
        setHeightState(600);
      } else if (newWidth < 1200) {
        setHeightState(700);
      } else if (newWidth < 1700) {
        setHeightState(750);
      } else if (newWidth < 2100) {
        setHeightState(900);
      } else {
        setProjectionScale(250); // Default scale
        setHeightState(1100);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial value

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const countryProjects = [
    {
      country: "Poland",
      details: [
        "TREND lab at PORT Polish Center for Technology Development",
        "Collaborations with Psychiatry Hospital Lodz, Nencki Institute of Experimental Biology, and Medical University of Wroclaw.",
      ],
    },
    {
      country: "Pakistan",
      details: [
        "PLMS project to assess the serum and sperm signatures of childhood trauma in a population with high consanguinity: In collaboration with the SOS Village and Chughtai Labs Private Limited, Pakistan.",
      ],
    },
    {
      country: "Bosnia and Herzegovina",
      details: [
        "ACTION Bosnia project to investigate the intergenerational transmission of the effects of Srebrenica Genocide in collaboration with the University Clinical Center Tuzla, Medical Faculty of the University of Tuzla, and Plava Polyclinic.",
      ],
    },
    {
      country: "Australia",
      details: [
        "TREMENDOS project to examine the role of microglia in the protective effects of environmental enrichment in neurodegenerative disorders in collaboration with The Florey Institute, Melbourne.",
        "MUSE ACE project to explore the interaction of peripheral lipids and microglia in the susceptibility vs. resilience to the long-term behavioral effects of adverse childhood experiences in collaboration with The Florey Institute, Melbourne.",
      ],
    },
    {
      country: "France",
      details: [
        "EMPATHY project to investigate the role of early metabolic programming of hypothalamic microglia-neuronal interactions in the risk of eating disorders in collaboration with the University of Bordeaux.",
      ],
    },
    {
      country: "Lithuania",
      details: [
        "EMPATHY project to investigate the role of early metabolic programming of hypothalamic microglia-neuronal interactions in the risk of eating disorders in collaboration with the Vilnius Life Sciences Center.",
      ],
    },
    {
      country: "USA",
      details: [
        "Collaboration with Prof. Paul Schulz at the University of Texas Health Science Center in Houston, TX on multiple translational studies, including targeting neurodegenerative disorders via metabolic pathways.",
      ],
    },
    {
      country: "Switzerland",
      details: [
        "TREMENDOS project to examine the role of microglia in the protective effects of environmental enrichment in neurodegenerative disorders in collaboration with the University of Lausanne.",
        "MUSE ACE project to explore the interaction of peripheral lipids and microglia in the susceptibility vs. resilience to the long-term behavioral effects of adverse childhood experiences in collaboration with the University of Lausanne.",
        "EMPATHY project to investigate the role of early metabolic programming of hypothalamic microglia-neuronal interactions in the risk of eating disorders in collaboration with the Vilnius Life Sciences Center.",
      ],
    },
  ];

  const geoPathGenerator = d3.geoPath().projection(projection);
  const countryNames = [
    "Poland",
    "Pakistan",
    "Bosnia and Herzegovina",
    "Australia",
    "France",
    "Lithuania",
    "USA",
    "Switzerland",
  ];

  const countryCoords = countryNames.map((name) => {
    const countryFeature = data.features.find(
      (feature) => feature.properties.name === name
    );
    if (countryFeature) {
      let lon, lat;
      [lon, lat] = d3.geoCentroid(countryFeature);
      if (name === "France") {
        lon = 2.2137; // France’s actual longitude
        lat = 46.6034; // France’s actual latitude
      }
      return { name, coords: projection([lon, lat]) };
    }
    return { name, coords: null };
  });

  const poland = countryCoords.find((c) => c.name === "Poland");

  const lineGenerator = d3
    .line()
    .x((d) => d[0])
    .y((d) => d[1])
    .curve(d3.curveBasis);

  return (
    <div className="w-full flex justify-center items-center">
      <svg width={"100%"} height={height}>
        {/* Countries  */}
        {data.features
          .filter((shape) => shape.id !== "ATA")
          .map((shape) => {
            const isInteractive = countryNames.includes(shape.properties.name);
            return (
              <path
                key={shape.id}
                d={geoPathGenerator(shape)}
                stroke="lightGrey"
                strokeWidth={0.5}
                fill="#a1adaf"
                fillOpacity={0.7}
                style={{
                  cursor: isInteractive ? "pointer" : "default",
                }}
                onClick={() => {
                  if (isInteractive) {
                    setSelectedCountry(shape.properties.name);
                  }
                }}
              />
            );
          })}

        {/* Dashed Lines */}
        {poland?.coords &&
          countryCoords.map(({ name, coords }, index) => {
            if (coords && name !== "Poland") {
              if (name === "Australia") {
                midPoint = [
                  (coords[0] + poland.coords[0]) / 2 + 80,
                  (coords[1] + poland.coords[1]) / 2 - 40,
                ];
              } else {
                midPoint = [
                  (coords[0] + poland.coords[0]) / 2,
                  (coords[1] + poland.coords[1]) / 2 - 40,
                ];
              }
              const pathData = lineGenerator([coords, midPoint, poland.coords]);

              return (
                <path
                  key={`line-${index}`}
                  d={pathData}
                  stroke="white"
                  strokeWidth={2}
                  fill="none"
                  opacity={0.7}
                />
              );
            }
            return null;
          })}

        {/* Pin Points  */}
        {countryCoords?.map(({ name, coords }, index) => {
          if (coords) {
            const [x, y] = coords;
            if (name === "Poland") {
              return (
                <foreignObject
                  key={index}
                  x={x - 10}
                  y={y - 20}
                  width="60"
                  height="65"
                  style={{ overflow: "visible", cursor: "pointer" }}
                  onClick={() => setSelectedCountry(name)}
                >
                  <div
                    style={{
                      transform: "scale(1.4)",
                      color: "#eda978",
                    }}
                  >
                    <FmdGood />
                  </div>
                </foreignObject>
              );
            } else {
              return (
                <foreignObject
                  key={index}
                  x={x - 15}
                  y={y - 20}
                  width="30"
                  height="35"
                  onClick={() => setSelectedCountry(name)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      transform: "scale(0.8)",
                      color: "#66cecf",
                    }}
                  >
                    <FmdGood />
                  </div>
                </foreignObject>
              );
            }
          }
          return null;
        })}
      </svg>

      <Dialog open={!!selectedCountry} onClose={() => setSelectedCountry(null)}>
        <DialogTitle>{selectedCountry}</DialogTitle>
        <DialogContent>
          {countryProjects
            .find((c) => c.country === selectedCountry)
            ?.details.map((detail, index) => <p key={index}>{detail}</p>) || (
            <p>No details available.</p>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConnectionMap;
