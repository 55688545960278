import React from "react";
import Slider from "react-slick";
import { Typography, IconButton } from "@mui/material";
import { convertImagePathToUrl } from "../../utils";
import { ArrowBackSharp, ArrowForwardSharp } from "@mui/icons-material";

// Custom Previous Arrow Component
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-prev"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "35%", // Center vertically
        left: "-10px", // Distance from the left side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowBackSharp />
    </IconButton>
  );
};

// Custom Next Arrow Component
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      className="custom-slick-next"
      style={{
        display: "flex",
        justifyContent: "center",
        width: "40px", // Set width for circle
        height: "40px", // Set height for circle
        fontSize: "25px",
        color: "white",
        backgroundColor: "#30698BB2",
        borderRadius: "100%", // Make it a circle
        position: "absolute",
        top: "35%", // Center vertically
        right: "-10px", // Distance from the right side
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <ArrowForwardSharp />
    </IconButton>
  );
};

export const TrendNewsCrousal = ({ trendNews }) => {
  // Check if trendNews is valid and has sub_headings
  if (!trendNews || !trendNews[0] || !trendNews[0].sub_headings) {
    return null; // or a loading indicator if data is being fetched
  }

  const sub_headings = trendNews[0].sub_headings;

  // Slick carousel settings
  var settings = {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />, // Custom Previous Arrow
    nextArrow: <CustomNextArrow />, // Custom Next Arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="TREND Trends" className="pt-12 text-white">
      <Typography
        className="text-center pb-5 text-4xl font-bold"
        style={{
          fontFamily: "Montserrat",
        }}
      >
        Trend Trends
      </Typography>
      {/* <div className="px-20 slider-container">
        <Slider {...settings}>
          {sub_headings.map((subHeading) => (
            <div className="flex justify-center">
              <div className="w-8/12">
                <a
                  href={subHeading.sub_heading_url || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    fontFamily: "Montserrat",
                  }}
                >
                  <img
                    src={convertImagePathToUrl(subHeading.sub_heading_bg_image)}
                    className="w-full"
                    alt=""
                  />
                  {subHeading?.sub_heading && (
                    <>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "Montserrat",
                        }}
                        className="pt-5"
                      >
                        {subHeading.sub_heading}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          color: "#79A7C7",
                          fontWeight: 700,
                        }}
                        className="pb-5"
                      >
                        <a
                          href={subHeading.sub_heading_url || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Read more
                        </a>
                      </Typography>
                    </>
                  )}
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
    </div>
  );
};
