import React, { useEffect, useState } from "react";
import { TrendNewsCrousal } from "./TrendNewsCrousal";
// import { Trends } from "./Trends";
import { TrendThemesPolygon } from "./TrendThemesPolygon";
import { Typography } from "@mui/material";
import ConnectionMap from "./ConnectionMap";

export const TrendThemes = ({ trendThemes, trendTrends, trendNews }) => {
  const [themeData, setthemeData] = useState([]);
  const [heightState, setHeightState] = useState(1100);

  const mergeObjectsByPosition = (inputArray) => {
    const mergedArray = [];
    // Iterate over each item in the input array
    inputArray.forEach((item) => {
      if (item.key === "sub-heading") {
        mergedArray.push({
          title: item.sub_heading,
          description: "",
          position: item.sub_heading_position,
          desc: item.sub_heading_desc,
          img: item.sub_heading_bg_image,
        });
      } else if (item.key === "desc") {
        const matchingObject = mergedArray.find(
          (obj) => obj.position === item.description_position
        );
        if (matchingObject) {
          matchingObject.description = item.description;
        }
      }
    });
    setthemeData(mergedArray);
  };
  useEffect(() => {
    trendThemes?.details?.map((detail) => {
      return mergeObjectsByPosition(detail?.singleArray);
    });
  }, [trendThemes]);

  return (
    <div id="TREND Themes" className="TrendTreatBg">
      <Typography
        className="flex justify-center text-4xl font-bold pt-20 text-white xl:py-32"
        style={{
          fontFamily: "Montserrat",
        }}
      >
        {trendThemes?.section_heading}
      </Typography>
      <div className="flex flex-col items-center justify-center xl:flex-row">
        {themeData?.map((theme) => (
          <TrendThemesPolygon trendThemes={trendThemes} item={theme} />
        ))}
      </div>
      {/* <Trends trendTrends={trendTrends.details} /> */}
      <TrendNewsCrousal trendNews={trendNews.details} />
      <div className="flex justify-center">
        <Typography
          className="text-white "
          style={{
            fontSize: 26,
            fontFamily: "Montserrat",
            fontWeight: 400,
          }}
        >
          TREND Collaborations
        </Typography>
      </div>
      <div className="flex justify-center items-center">
        <ConnectionMap
          width={1200}
          height={heightState}
          setHeightState={setHeightState}
        />
      </div>
    </div>
  );
};
