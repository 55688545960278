import { useEffect } from "react";

function DialogBox({ show, onClose, type }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`bg-white p-6 rounded-lg shadow-lg text-center w-96 border-t-4 ${
          type === "Success" ? "border-green-500" : "border-red-500"
        }`}
      >
        <div className="flex flex-col items-center">
          <h2
            className={`text-lg font-bold mt-2 ${
              type === "Success" ? "text-green-600" : "text-red-600"
            }`}
          >
            {type === "Success" ? "SUCCESS" : "ERROR!"}
          </h2>
          <p className="text-gray-700 font-semibold mt-2">
            {type === "Success"
              ? "Form Submitted Successfully"
              : "Error Submitting Form"}
          </p>
          <p className="text-gray-500 text-sm mt-1">
            {type === "Success"
              ? "We Will Shortly Contact You!"
              : "Please Try Again!"}
          </p>
          <button
            onClick={onClose}
            className={`px-4 py-2 mt-4 rounded-lg transition font-semibold ${
              type === "Success"
                ? "bg-green-500 hover:bg-green-600"
                : "bg-red-500 hover:bg-red-600"
            } text-white`}
          >
            {"Continue"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DialogBox;
