import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";
import whiteArrowLeft from "../../assets/images/WhiteArrowLeft.png";

export const TeamLoop1 = ({ Loop1 }) => {
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Extract image, sub-heading, and descriptions from the Loop1 object
  const image = Loop1.find((item) => item.key === "image");
  const subHeading = Loop1.find((item) => item.key === "sub-heading");

  console.log("Here", subHeading);
  // const descriptions = Loop1.filter((item) => item.key === "desc");

  // Find the smallest and largest description_id
  // const description1 =
  //   descriptions.length > 0
  //     ? descriptions.reduce((min, desc) =>
  //         desc.description_id < min.description_id ? desc : min
  //       )
  //     : null;
  // const description2 =
  //   descriptions.length > 0
  //     ? descriptions.reduce((max, desc) =>
  //         desc.description_id > max.description_id ? desc : max
  //       )
  //     : null;

  return (
    <div
      style={{
        backgroundColor: "#EBEEF3",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <div
        className="flex justify-between"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        {/* Image 1 */}
        <div className="justify-end hidden lg:flex" style={{ flex: 1 }}>
          {image && (
            <img
              src={whiteArrowLeft}
              alt="Team1"
              className="ml-auto leftArrowStyle h-[700px]"
            />
          )}
        </div>

        {/* Content */}
        <div
          className="h-full md:h-[700px] flex flex-col items-center bg-white"
          style={{ flex: 2 }}
        >
          <div className="py-24 mx-20 lg:mx-0" style={{ maxWidth: "580px" }}>
            {/* Conditionally render title or new div based on sub_heading_position */}
            {subHeading && (
              <>
                {subHeading.sub_heading_position === 1 && (
                  <Typography
                    className="pb-8 text-4xl font-bold"
                    style={{
                      fontFamily: "Montserrat",
                    }}
                  >
                    TREND Team
                  </Typography>
                )}
                {subHeading.sub_heading_position === 3 && (
                  <div
                    className="pb-16"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {/* Your custom content or placeholder */}
                  </div>
                )}
              </>
            )}
            {/* Sub-heading */}
            {subHeading && (
              <>
                <Typography
                  className="pb-8"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {subHeading.sub_heading}
                </Typography>
                <Typography
                  className="pb-8"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: formatModalDescription(subHeading.sub_heading_desc), // Correctly using dangerouslySetInnerHTML here
                  }}
                />
              </>
            )}
          </div>
        </div>

        {/* Image 2 */}
        <div
          className="hidden bg-white lg:block max-h-[700px]"
          style={{ flex: 1 }}
        >
          {image && (
            <img
              src={convertImagePathToUrl(subHeading.sub_heading_image)}
              alt="Team2"
              className="h-full ml-auto leftArrowStyle"
            />
          )}
        </div>
      </div>
    </div>
  );
};
