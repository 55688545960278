import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import "../../assets/css/scientist.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";
import polygonSvg from "../../assets/images/Polygon.svg";

export const PolygonLoop = ({ item, topPad = false }) => {
  const formatModalDescription = (description) => {
    if (!description) return "";

    // Check if "Media:" is present in the description
    if (description.includes("Media:")) {
      // Split the description into the main text and media part
      const [mainText, mediaText] = description.split("Media:");

      // Format the output with separate styles
      return `
      <span style="font-size: 18px;">${mainText.trim()}</span><br />
      <span class="text-sm italic">(Media: ${mediaText.trim()})</span>
    `;
    }

    // Default behavior for descriptions without "Media:"
    return description.replace(/(\r\n|\r|\n)/g, "<br>");
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
      offset: 500,
    });
  }, []);

  return (
    <a
      className="cursor-pointer"
      key={item.id}
      href={item.sub_heading_url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
      data-aos="fade-in"
      data-aos-delay="500"
    >
      <div
        className="flex flex-col justify-center items-center w-full h-full text-white xl:px-16 max-w-[460px]"
        style={{
          marginTop: topPad ? "50px" : "0px",
          backgroundImage: item.sub_heading_bg_image
            ? `url(${convertImagePathToUrl(item.sub_heading_bg_image)})`
            : `url(${polygonSvg})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // height: "530px",
          // width: "450px",
        }}
      >
        <Typography
          className="text-center font-bold"
          style={{
            fontFamily: "Montserrat",
            textShadow: "0px 0px 5px black",
          }}
        >
          {item?.sub_heading}
        </Typography>
        <Typography
          className="mt-3 text-center 2xl:mt-10 font-medium"
          style={{
            fontFamily: "Montserrat",
            textShadow: "0px 0px 5px black",
          }}
          dangerouslySetInnerHTML={{
            __html: formatModalDescription(item?.sub_heading_desc), // Correctly using dangerouslySetInnerHTML here
          }}
        />
      </div>
    </a>
  );
};
