import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { ArrowUpwardSharp } from "@mui/icons-material";
import DialogBox from "./DialogBox";
import { toast, ToastContainer } from "react-toastify";

export const ConsultancyForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    subject: "",
    message: "",
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseType, setResponseType] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.fullName ||
      !formData.emailAddress ||
      !formData.phoneNumber ||
      !formData.subject ||
      !formData.message
    ) {
      toast.error("Please Fill in all Fields", {
        autoClose: 3000,
        closeOnClick: true,
      });
      return;
    }
    if (loading) return;
    try {
      setLoading(true);
      toast.loading("Sending Message", { toastId: "message-toast" });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mail`,
        formData
      );
      // let response = { status: 200 };
      console.log(response.status);
      if (response.status === 200) {
        setOpen(true);
        setResponseType("Success");
      } else {
        setOpen(true);
        setResponseType("Error");
      }
      setFormData({
        fullName: "",
        phoneNumber: "",
        emailAddress: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      setOpen(true);
      setResponseType("Error");
    } finally {
      toast.dismiss("message-toast");
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    document.getElementById("hero").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div
        id="Lets Connect"
        className="flex items-center justify-center px-10 py-20 h-1/2"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <div className="flex flex-col pt-5 md:flex-row ">
            <div className="flex flex-col md:mr-8 ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Full Name
              </label>
              <TextField
                name="fullName"
                value={formData.fullName}
                type="text"
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
            <div className="flex flex-col ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Phone Number
              </label>
              <TextField
                name="phoneNumber"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
          </div>
          <div className="flex flex-col pt-5 md:flex-row">
            <div className="flex flex-col md:mr-8 ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Email Address
              </label>
              <TextField
                name="emailAddress"
                value={formData.emailAddress}
                type="email"
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
            <div className="flex flex-col ">
              <label
                className="py-3 text-white"
                style={{ fontSize: 16, fontWeight: 600 }}
              >
                Subject
              </label>
              <TextField
                name="subject"
                value={formData.subject}
                type="text"
                onChange={handleChange}
                className="bg-white rounded-lg w-80"
              />
            </div>
          </div>
          <div className="flex flex-col py-3">
            <label
              className="py-3 text-white"
              style={{ fontSize: 16, fontWeight: 600 }}
            >
              Your Message
            </label>
            <TextField
              name="message"
              value={formData.message}
              onChange={handleChange}
              type="text"
              className="bg-white rounded-lg w-80 md:w-full"
              multiline={true} // Enables multiline (textarea-like behavior)
              rows={4} // Sets the initial number of rows
              variant="outlined" // Optional: for a border around the textarea
            />
          </div>
          <div className="flex items-end justify-end mt-3 ">
            <Button
              type="submit"
              className="w-40 text-white border-2 border-solid rounded-lg text-decoration-none"
              style={{
                fontSize: 14,
                backgroundColor: "#3778A5",
                textTransform: "none",
              }}
              disabled={loading}
            >
              Send Message
            </Button>
          </div>
        </Box>
      </div>
      <div>
        {/* Up arrow to scroll to top */}
        <div className="flex justify-end mb-10 mr-10">
          <IconButton
            onClick={scrollToTop}
            style={{ backgroundColor: "#35558C" }}
          >
            <ArrowUpwardSharp fontSize="small" style={{ color: "white" }} />
          </IconButton>
        </div>
      </div>

      <DialogBox
        show={open}
        onClose={() => setOpen(false)}
        type={responseType}
      />
      <ToastContainer
        hideProgressBar
        theme="colored"
        position="bottom-center"
      />
    </>
  );
};
